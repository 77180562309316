import { wrap, wrapGql } from "../_"

type UpdateUserData = any // TODO
type CreateUserData = any // TODO
type CreateUserQuickData = any // TODO

interface SendTokenData { email: string, status?: string }

const pfx = "/user"

export default (a: NuxtAxiosInstance) => ({
  getFavoriteUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/favorite`,
      { ...cfg })),

  addFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/${userId}/favorite`, {},
      { ...cfg })),

  removeFavoriteUser: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/${userId}/favorite`,
      { ...cfg })),

  getUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}`,
      { cache: true, ...cfg })),

  updateUser: (data: UpdateUserData, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}`, data,
      { ...cfg })),

  getUser: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/my`,
      { ...cfg })),

  getCountries: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/countries`,
      { cache: true, ...cfg })),

  getColleague: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/colleague/${userId}`,
      { ...cfg })),

  updateColleague: (userId: string, data: UpdateUserData, cfg?: AxiosConfig) =>
    wrap(
      a.$patch(`${pfx}/colleague/${userId}`, data,
        { ...cfg })),

  createColleague: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/colleague`, data,
      { ...cfg })),

  createUser: (data: CreateUserData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}`, data,
      { ...cfg })),

  sendToken: (data: SendTokenData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/registration-token`, data,
      { ...cfg })),

  getTokenData: (token: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/registration-token/${encodeURI(token)}`,
      { ...cfg })),

  createUserQuick: (data: CreateUserQuickData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/quick-registration`, data,
      { ...cfg })),

  getPreviousData: (token: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/previous-user/${token}`,
      { ...cfg })),

  getA5KUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/a5k`,
      { cache: true, ...cfg })),

  getRecommendedUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/recommended`,
      { ...cfg })),

  getVideoLink: (cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        query {
          listMyTask1 {
            items {
              urlTask
            }
          }
        }
      `,
    }, { ...cfg }),
  ),

  setVideoLink: (link: string, cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        mutation ($link: String) {
          createTask1(urlTask: $link)
        }
      `,
      variables: { link },
    }, { ...cfg }),
  ),

  getPostLink: (cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        query {
          listMyTask2 {
            items {
              urlTask
            }
          }
        }
      `,
    }, { ...cfg }),
  ),

  setPostLink: (link: string, cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        mutation ($link: String) {
          createTask2(urlTask: $link)
        }
      `,
      variables: { link },
    }, { ...cfg }),
  ),

  getPostLink2: (cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        query {
          listMyTask3 {
            items {
              urlTask
            }
          }
        }
      `,
    }, { ...cfg }),
  ),

  setPostLink2: (link: string, cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        mutation ($link: String) {
          createTask3(urlTask: $link)
        }
      `,
      variables: { link },
    }, { ...cfg }),
  ),

  getPostLink4: (cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        query {
          listMyTask4 {
            items {
              urlTask
            }
          }
        }
      `,
    }, { ...cfg }),
  ),

  setPostLink4: (link: string, cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        mutation ($link: String) {
          createTask4(urlTask: $link)
        }
      `,
      variables: { link },
    }, { ...cfg }),
  ),

  getPostLink5: (cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        query {
          listMyTask5 {
            items {
              urlTask
            }
          }
        }
      `,
    }, { ...cfg }),
  ),

  setPostLink5: (link: string, cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        mutation ($link: String) {
          createTask5(urlTask: $link)
        }
      `,
      variables: { link },
    }, { ...cfg }),
  ),

  getPostLink6: (cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        query {
          listMyTask6 {
            items {
              urlTask
            }
          }
        }
      `,
    }, { ...cfg }),
  ),

  setPostLink6: (link: string, cfg?: AxiosConfig) => wrap(
    a.$post("/graphql", {
      query: `
        mutation ($link: String) {
          createTask6(urlTask: $link)
        }
      `,
      variables: { link },
    }, { ...cfg }),
  ),

  findUser: (id: string, cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        query ($id: UUID!) {
          findUser(id: $id) {
            id
            # oauthID
            firstName {
              current
            }
            lastName {
              current
            }
            patronymic {
              current
            }
          }
        }
      `,
      variables: { id },
    }, { ...cfg }),
    "findUser",
  ),

  findUserByBp: (bpID: string, statuses: string[] = [], cfg?: AxiosConfig) =>
    wrapGql(
      a.$post("/graphql", {
        query: `
        query ($bpID: String! $statuses: [String!]!) {
          findUserByBP(bpID: $bpID statuses: $statuses) {
            id
            firstName {
              current
            }
            lastName {
              current
            }
            patronymic {
              current
            }
          }
        }
      `,
        variables: { bpID, statuses },
      }, { ...cfg }),
      "findUserByBP",
    ),

  createDog: (variables: any, cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation (
          $secondAttendant: UUID
          $participants: [UUID]
          $program: String
          $disabled: Boolean
          $city: String
          $lastName: String
          $programHotel: Boolean
          $disabledCategory: String
          $transfer: String
          $transfer2: String
          $arrivalPlace: String
          $firstName: String
          $number1: String
          $patronymic: String
          $departurePlace: String
          $number3: String
          $departureDate: DateTime
          $departureTime: DateTime
          $telephone: String
          $arrivalDate: DateTime
          $arrivalTime: DateTime
        ) {
           createDog (
             secondAttendant: $secondAttendant
             participants: $participants
             program: $program
             disabled: $disabled
             city: $city
             lastName: $lastName
             programHotel: $programHotel
             disabledCategory: $disabledCategory
             transfer: $transfer
             transfer2: $transfer2
             arrivalPlace: $arrivalPlace
             firstName: $firstName
             number1: $number1
             patronymic: $patronymic
             departurePlace: $departurePlace
             number3: $number3
             departureDate: $departureDate
             departureTime: $departureTime
             telephone: $telephone
             arrivalDate: $arrivalDate
             arrivalTime: $arrivalTime
          )
        }
      `,
      variables,
    }, { ...cfg }),
    "createDog",
  ),

  getDog: (cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listMyDog {
            items {
              id
              creationTime
              updateTime
              author
              # secondAttendantBPID
              secondAttendant {
                id
                firstName {
                  current
                }
                lastName {
                  current
                }
                patronymic {
                  current
                }
              }
              participants {
                id
                firstName {
                  current
                }
                lastName {
                  current
                }
                patronymic {
                  current
                }
              }
              program
              state
              disabled
              city
              lastName
              programHotel
              disabledCategory
              transfer
              transfer2
              arrivalPlace
              dateparticipation
              firstName
              number1
              patronymic
              departurePlace
              number3
              departureDate
              departureTime
              telephone
              arrivalDate
              arrivalTime
              returnComment
              sferumInviteLink
            }
          }
        }
      `,
    }, { ...cfg }),
    "listMyDog",
  ),

  updateDog: (variables: any, cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation (
          $id: UUID
          $secondAttendant: UUID
          $participants: [UUID]
          $program: String
          $disabled: Boolean
          $city: String
          $lastName: String
          $programHotel: Boolean
          $disabledCategory: String
          $transfer: String
          $transfer2: String
          $arrivalPlace: String
          $firstName: String
          $number1: String
          $patronymic: String
          $departurePlace: String
          $number3: String
          $departureDate: DateTime
          $departureTime: DateTime
          $telephone: String
          $arrivalDate: DateTime
          $arrivalTime: DateTime
        ) {
           updateDog (
             id: $id
             secondAttendant: $secondAttendant
             participants: $participants
             program: $program
             disabled: $disabled
             city: $city
             lastName: $lastName
             programHotel: $programHotel
             disabledCategory: $disabledCategory
             transfer: $transfer
             transfer2: $transfer2
             arrivalPlace: $arrivalPlace
             firstName: $firstName
             number1: $number1
             patronymic: $patronymic
             departurePlace: $departurePlace
             number3: $number3
             departureDate: $departureDate
             departureTime: $departureTime
             telephone: $telephone
             arrivalDate: $arrivalDate
             arrivalTime: $arrivalTime
          )
        }
      `,
      variables,
    }, { ...cfg }),
    "updateDog",
  ),

  getDogAsParticipant: (cfg?: AxiosConfig) => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          listMyDogByParticipants {
            id
            bpID
            state
            lastName
            timeparticipation
            dateparticipation
            firstName
            patronymic
            departureDate
            departureTime
            telephone
            arrivalDate
            arrivalTime
            returnComment
            secondAttendant {
              id
            }
            sferumInviteLink
          }
        }
      `,
    }, { ...cfg }),
    "listMyDogByParticipants",
  ),

  createQuickUser: (variables: Record<string, any>, cfg?: AxiosConfig) =>
    wrapGql(
      a.$post("/graphql", {
        query: `
          mutation (
            $key: String
            $email: String
            $firstName: String
            $lastName: String
            $patronymic: String
            $status: UUID
            $sex: UUID
            $phone: String
            $region: String
            $birthDate: DateTime
          ) {
            createQuickUser (
              key: $key
              email: $email
              firstName: $firstName
              lastName: $lastName
              patronymic: $patronymic
              status: $status
              sex: $sex
              phone: $phone
              region: $region
              birthDate: $birthDate
            ) {
              id
              oauthID
            }
          }
        `,
        variables,
      }, { ...cfg }),
      "createQuickUser",
    ),

  downloadGroupTicket: (dog: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        query ($dog: UUID!) {
          downloadGroupTicket (dog: $dog) {
            body
            name
            mimetype
          }
        }
      `,
      variables: { dog },
    }, { responseType: "blob" }),
    "downloadGroupTicket",
    true,
  ),

  downloadUserTicket: () => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          downloadUserTicket {
            body
            name
            mimetype
          }
        }
      `,
    }, { responseType: "blob" }),
    "downloadUserTicket",
    true,
  ),

  markVisit: (dog: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($dog: UUID!) {
          markVisit (dog: $dog)
        }
      `,
      variables: { dog },
    }),
    "markVisit",
  ),
})
