// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uzhV8IIl{background:var(--color-bg)}._0RcO6ZZE{display:flex;flex-direction:column;flex-grow:1;padding:var(--spacing-lg) 0}@media(max-width:667.98px){._0RcO6ZZE{padding:var(--spacing-sm) 0}}.blagf41F{color:var(--color-fg);font-size:2.13rem;font-weight:700;line-height:1.47;margin-bottom:calc(var(--spacing-default)*5)}@media(max-width:667.98px){.blagf41F{font-size:1.5rem;margin-bottom:var(--spacing-sm)}}.veb4OBjC{border-bottom:1px solid var(--color-gray-2);display:flex;height:2.5rem}.veb4OBjC>*{background:var(--color-gray-4);border-radius:9999px;height:.63rem;opacity:.5;overflow:hidden;width:4.69rem}.veb4OBjC>:not(:last-child){margin-right:calc(var(--spacing-md)*2)}._2ZzZYF8- .el-tabs__header{margin:0}._2ZzZYF8- .el-tabs__nav{transform:none!important}._2ZzZYF8- .el-tabs__nav-next,._2ZzZYF8- .el-tabs__nav-prev{display:none}._2ZzZYF8- .el-tabs__nav-wrap{padding:0}._2ZzZYF8- .el-tabs__nav-wrap:after{background:var(--color-gray-2);height:1px}._2ZzZYF8- .el-tabs__nav-scroll{-ms-overflow-style:none;overflow:auto;scrollbar-width:none}._2ZzZYF8- .el-tabs__nav-scroll::-webkit-scrollbar{display:none}._2ZzZYF8- .el-tabs__active-bar{height:0}._2ZzZYF8- .el-tabs__item{color:var(--color-gray-4);font-size:.88rem;font-weight:600;height:2.5rem;letter-spacing:.75px;line-height:1.21;opacity:.4;padding:0 var(--spacing-md)}@media(hover:hover){._2ZzZYF8- .el-tabs__item:hover{opacity:1}}@media(hover:none){._2ZzZYF8- .el-tabs__item:active{opacity:1}}@media(max-width:667.98px){._2ZzZYF8- .el-tabs__item{font-size:.81rem;height:1.88rem;padding:0 var(--spacing-sm)}}._2ZzZYF8- .el-tabs__item.is-active{cursor:default;opacity:1}._2ZzZYF8- .el-tabs__item.is-active a{cursor:inherit}._2ZzZYF8- .el-tabs__item a{display:block;height:100%}.xST7YcSA{position:relative}.xST7YcSA:after{background:var(--color-primary-on-bg);border-radius:9999px;content:\"\";height:.5rem;overflow:hidden;position:absolute;right:-.63rem;top:-.13rem;width:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"layout": "uzhV8IIl",
	"container": "_0RcO6ZZE",
	"heading": "blagf41F",
	"tabsPlaceholder": "veb4OBjC",
	"tabs": "_2ZzZYF8-",
	"tabAttention": "xST7YcSA"
};
module.exports = ___CSS_LOADER_EXPORT___;
