import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _29f8dfcd = () => interopDefault(import('../pages/user/index.ts' /* webpackChunkName: "pages/user/index" */))
const _3fdcfe1b = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _1c75ff9a = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _2cc60db5 = () => interopDefault(import('../pages/auth/registration-short.vue' /* webpackChunkName: "pages/auth/registration-short" */))
const _263f5db6 = () => interopDefault(import('../pages/user/group.vue' /* webpackChunkName: "pages/user/group" */))
const _1c2f038b = () => interopDefault(import('../pages/user/group-participant.vue' /* webpackChunkName: "pages/user/group-participant" */))
const _965279dc = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _49feebf0 = () => interopDefault(import('../pages/user/quest.vue' /* webpackChunkName: "pages/user/quest" */))
const _3c36ac54 = () => interopDefault(import('../pages/user/ticket.vue' /* webpackChunkName: "pages/user/ticket" */))
const _1f6e44c8 = () => interopDefault(import('../pages/user/ticket-group.vue' /* webpackChunkName: "pages/user/ticket-group" */))
const _2a6fc7db = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _666f9ce9 = () => interopDefault(import('../pages/dog/visit/_id.vue' /* webpackChunkName: "pages/dog/visit/_id" */))
const _69beb993 = () => interopDefault(import('../pages/news/_alias.vue' /* webpackChunkName: "pages/news/_alias" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/faq",
    component: _ca324bc8,
    name: "faq___ru___default"
  }, {
    path: "/news",
    component: _076ad200,
    name: "news___ru___default"
  }, {
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/user",
    component: _29f8dfcd,
    name: "user___ru___default"
  }, {
    path: "/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___ru___default"
  }, {
    path: "/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru___default"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru___default"
  }, {
    path: "/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___ru___default"
  }, {
    path: "/auth/registration-short",
    component: _2cc60db5,
    name: "auth-registration-short___ru___default"
  }, {
    path: "/ru/faq",
    component: _ca324bc8,
    name: "faq___ru"
  }, {
    path: "/ru/news",
    component: _076ad200,
    name: "news___ru"
  }, {
    path: "/ru/user",
    component: _29f8dfcd,
    name: "user___ru"
  }, {
    path: "/user/group",
    component: _263f5db6,
    name: "user-group___ru___default"
  }, {
    path: "/user/group-participant",
    component: _1c2f038b,
    name: "user-group-participant___ru___default"
  }, {
    path: "/user/profile",
    component: _965279dc,
    name: "user-profile___ru___default"
  }, {
    path: "/user/quest",
    component: _49feebf0,
    name: "user-quest___ru___default"
  }, {
    path: "/user/ticket",
    component: _3c36ac54,
    name: "user-ticket___ru___default"
  }, {
    path: "/user/ticket-group",
    component: _1f6e44c8,
    name: "user-ticket-group___ru___default"
  }, {
    path: "/ru/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___ru"
  }, {
    path: "/ru/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru"
  }, {
    path: "/ru/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru"
  }, {
    path: "/ru/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___ru"
  }, {
    path: "/ru/auth/registration-short",
    component: _2cc60db5,
    name: "auth-registration-short___ru"
  }, {
    path: "/ru/user/group",
    component: _263f5db6,
    name: "user-group___ru"
  }, {
    path: "/ru/user/group-participant",
    component: _1c2f038b,
    name: "user-group-participant___ru"
  }, {
    path: "/ru/user/profile",
    component: _965279dc,
    name: "user-profile___ru"
  }, {
    path: "/ru/user/quest",
    component: _49feebf0,
    name: "user-quest___ru"
  }, {
    path: "/ru/user/ticket",
    component: _3c36ac54,
    name: "user-ticket___ru"
  }, {
    path: "/ru/user/ticket-group",
    component: _1f6e44c8,
    name: "user-ticket-group___ru"
  }, {
    path: "/ru/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/ru/dog/visit/:id?",
    component: _666f9ce9,
    name: "dog-visit-id___ru"
  }, {
    path: "/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru___default"
  }, {
    path: "/dog/visit/:id?",
    component: _666f9ce9,
    name: "dog-visit-id___ru___default"
  }, {
    path: "/ru/news/:alias",
    component: _69beb993,
    name: "news-alias___ru"
  }, {
    path: "/news/:alias",
    component: _69beb993,
    name: "news-alias___ru___default"
  }, {
    path: "/ru/*",
    component: _2efaf336,
    name: "all___ru"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ru___default"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all___ru___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
