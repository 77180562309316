// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bqpo5JBS{-moz-column-gap:max(.7rem,min(1.04vw,1.25rem));column-gap:max(.7rem,min(1.04vw,1.25rem));flex-wrap:wrap;row-gap:max(.7rem,min(1.04vw,1.25rem))}.U\\+XWkpyW,.Bqpo5JBS{align-items:center;display:flex}.U\\+XWkpyW{justify-content:center}.U\\+XWkpyW img{max-height:max(2.46rem,min(3.65vw,4.38rem));max-width:max(2.81rem,min(4.17vw,5rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"logos": "Bqpo5JBS",
	"logo": "U+XWkpyW"
};
module.exports = ___CSS_LOADER_EXPORT___;
