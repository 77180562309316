export const actions = {
  async nuxtServerInit ({ dispatch }) {
    await Promise.all([
      dispatch("app/FETCH_EVENT"),
      dispatch("app/FETCH_MENU"),
      dispatch("user/fetchParticipationDogs"),
      dispatch("user/fetchOwnedDogs"),
      // dispatch("live/FETCH_HALLS"),
    ])
  },
}
