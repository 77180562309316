
import { mapState, mapGetters } from "vuex"
import layoutMixin from "@/mixins/layout"

export default layoutMixin.extend({
  middleware: "auth",

  computed: {
    ...mapState("user", ["unreadMessagesCount"]),
    ...mapGetters("user", [
      "user",
      "isPayable",
      "isApprovable",
      "isApproved",
      "isAdmin",
      "participationDogsAvailable",
      "groupTicketAvailable",
    ]),

    isPaymentVisible (): boolean {
      return this.isPayable
    },

    tabs (): string[] {
      const { alias: status } = this.user.status

      const tabs = [
        "profile",
      ]

      if (
        [
          "attendant-adult",
          "attendant-teacher",
        ].includes(status)
      ) tabs.push("group")

      if (this.participationDogsAvailable) tabs.push("group-participant")

      tabs.push("ticket")

      if (this.groupTicketAvailable) tabs.push("ticket-group")

      if (
        [
          "student",
          "student-ssuz",
        ].includes(status)
      ) tabs.push("quest")

      return tabs
    },

    tab: {
      get (): string {
        const { tabs } = this
        return tabs.find(t => this.$route.path.includes(t)) || tabs[0]
      },
      set (tab: string) {
        // FIXME
        if (!this.$route.path.includes(tab))
          this.$router.push(this.localePath(`/user/${tab}`))
      },
    },
  },
})
