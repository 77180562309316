
import Vue from "vue"
import lPick from "lodash/pick"

const links = {
  russia: "",
  pervie: "",
  rsv: "",
  vdnkh: "",
  sber: "",
}

export default Vue.extend({
  props: {
    pick: {
      type: Array as VuePropType<(keyof typeof links)[]>,
      default: () => [],
    },
  },

  data: () => ({
    links,
  }),

  computed: {
    pickedLinks (): typeof links {
      const { pick, links } = this
      if (!pick.length) return links
      return lPick(links, pick)
    },
  },
})
