
import throttle from "lodash/throttle"
import { mapState, mapGetters } from "vuex"
import menuMixin from "@/mixins/menu"

export default menuMixin.extend({
  data: () => ({
    navOpen: false,
    sm: false,
  }),

  computed: {
    ...mapState("app", ["isRegClosed"]),
    ...mapGetters("user", ["user"]),
  },

  mounted () {
    const { onScroll } = this
    onScroll()
    window.addEventListener("scroll", () => onScroll(), { passive: true })
  },

  beforeDestroy () {
    window.removeEventListener("scroll", () => this.onScroll)
  },

  methods: {
    onScroll: throttle(
      function (this: any) {
        this.sm = window.scrollY > 0
      }, 400),
  },
})
