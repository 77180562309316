// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y\\+3yt000{background:var(--color-bg);padding:max(1.76rem,min(2.6vw,3.13rem)) 0}.y\\+3yt000 ._4oWuFixV{justify-content:center}.fIkmK0wR{-moz-column-gap:max(1.76rem,min(2.6vw,3.13rem));column-gap:max(1.76rem,min(2.6vw,3.13rem));row-gap:max(1.41rem,min(2.08vw,2.5rem))}.G\\+yhWwPn,.fIkmK0wR,.FruoAxHP{align-items:center;display:flex;flex-wrap:wrap;justify-content:center}.G\\+yhWwPn,.FruoAxHP{-moz-column-gap:max(1.05rem,min(1.56vw,1.88rem));column-gap:max(1.05rem,min(1.56vw,1.88rem));font-size:.75rem;font-weight:500;line-height:normal;row-gap:max(.81rem,min(1.2vw,1.44rem));text-align:center}@media(hover:hover){.G\\+yhWwPn a:hover,.FruoAxHP a:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.G\\+yhWwPn a:active,.FruoAxHP a:active{-webkit-text-decoration:underline;text-decoration:underline}}.G\\+yhWwPn{align-items:center;-moz-column-gap:max(2.11rem,min(3.13vw,3.75rem));column-gap:max(2.11rem,min(3.13vw,3.75rem));display:flex;flex-wrap:wrap;font-size:1rem;justify-content:center;row-gap:max(.81rem,min(1.2vw,1.44rem))}@media(max-width:667.98px){.G\\+yhWwPn{align-items:center;flex-direction:column}}.G\\+yhWwPn>*{align-items:center;display:flex;flex-direction:column}.vJOw8\\+jF{font-size:.8em;font-weight:400;margin-bottom:.4em;max-width:30ch;opacity:.6}.KhntjTZE>*+*{margin-top:.5em}._54CqdlHi{font-size:.6em;opacity:.8}.iAw9JDAi{display:flex;justify-content:center;margin-top:max(1.41rem,min(2.08vw,2.5rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "y+3yt000",
	"logos": "_4oWuFixV",
	"content": "fIkmK0wR",
	"contacts": "G+yhWwPn",
	"links": "FruoAxHP",
	"contactTitle": "vJOw8+jF",
	"contactInner": "KhntjTZE",
	"contactSub": "_54CqdlHi",
	"socialsWrapper": "iAw9JDAi"
};
module.exports = ___CSS_LOADER_EXPORT___;
